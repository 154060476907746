<template>
  <div class="h5-home">
    <div class="home-banner">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <template v-for="item of homeList">
          <van-swipe-item>
            <img :src="item.image" @click="onClickBanner(item)">
          </van-swipe-item>
        </template>
      </van-swipe>
    </div>
    <div class="home-nav">
      <div class="nav-wrapper">
        <van-row>
          <van-col span="12" v-for="(item, index) of navList" :key="index" v-if="index <= 3">
            <img :src="item.src" @click="onClickNav(item)">
          </van-col>
        </van-row>
      </div>
      <div class="menu-wrapper">
        <van-row>
          <van-col span="8" v-for="(item, index) of menuList" :key="index">
            <img :src="item.src" @click="onClickMenu(item)">
          </van-col>
        </van-row>
      </div>
    </div>
    <div>
      <img class="index_bottom_image" src="https://pic.eaworks.cn/chenghua/index_main_bottom.png"
           alt=""/>
    </div>
    <div class="firework-wrapper" v-if="showJFWrapper" @click="cancelJFWrapper">
      <div class="jf-canvas" v-if="showFirework">
        <div id="js-firework-wrapper">
          <canvas id="js-firework-canvas"></canvas>
        </div>
      </div>
      <div class="jf-pic" v-if="showJFCard">
        <img class="_top" src="https://pic.eaworks.cn/chenghua/icon_jf_top.png">
        <div class="_pic">
          <img :src="jfPic" @click.stop="onClickJFPic">
        </div>
        <img class="_bottom" src="https://pic.eaworks.cn/chenghua/icon_jf_bottom.png">
      </div>
    </div>

    <!-- 通用弹窗 -->
    <div class="dialog-wrapper" v-if="showDialog">
      <div class="dialog-content" v-if="dialogType === 1" @click="jumpVideoDetail">
        <div class="img-wrapper" style="padding-top: 5">
          <img :src="showImg">
        </div>
      </div>
      <div class="dialog-closed">
        <span @click="cancelWrapperDialog"><img
          src="https://pic.eaworks.cn/chenghua/icon_closed.png"></span>
      </div>
      <div class="dialog-bg"></div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";

export default {
  mixins: [],
  components: {},
  data() {
    return {
      showJFWrapper: false,
      showFirework: false,
      showJFCard: false,
      initFireworkCanvas: false,
      loadFireworkCanvasIndex: 0,
      showDialog: false,
      currVideoId: "",
      dialogType: 0,
      homeList: [],
      showImg: "",
      navList: [{
        // 美食地图
        src: 'https://pic.eaworks.cn/chenghua/nav_a.png',
        url: "/map"
      }, {
        // 每日吉福
        src: 'https://pic.eaworks.cn/chenghua/nav_b.png',
        url: "/shop/videoList"
      }, {
        // 名菜名店
        src: 'https://pic.eaworks.cn/chenghua/nav_c.png',
        url: "/shop/mingdian"
      }, {
        // 名菜名店
        src: 'https://pic.eaworks.cn/chenghua/nav_d.png',
        url: "/shop/year"
      }],
      menuList: [{
        src: 'https://pic.eaworks.cn/chenghua/0607/cdmd.png',
        url: "/shop/list?type=06d08bd6-0c70-ee06-358a-a310b3c25e50"
      }, {
        src: 'https://pic.eaworks.cn/chenghua/menu_b.png',
        url: "/shop/list?type=2d8a97d7-dde6-534a-326f-85c8dd3ff661"
      }, {
        src: 'https://pic.eaworks.cn/chenghua/menu_c.png',
        url: "/shop/list?type=b623ef1a-8845-c8a9-b5ce-7b78fefc9717"
      }, {
        src: 'https://pic.eaworks.cn/chenghua/0607/texc.png',
        url: "/shop/list?type=a78b6fad-7bec-0da0-f414-a38215549f76"
      }, {
        src: 'https://pic.eaworks.cn/chenghua/0607/yyfw.png',
        url: "/shop/list?type=ea3d253a-352f-fccf-527c-1ed65641d75e"
      }, {
        src: 'https://pic.eaworks.cn/chenghua/menu_f.png',
        url: "/shop/list?type=c51d2566-6195-9c93-f35f-bcd780ec6b05"
      }],
      jfPic: ''
    };
  },

  created() {

  },

  mounted() {
    this.ajaxGetBannerList()
  }
  ,

  methods: {
    showWrapperDialog(type) {
      this.showDialog = true;
      this.dialogType = type;
    }
    ,
    cancelWrapperDialog() {
      this.showDialog = false;
      this.dialogType = 0;
    }
    ,
    jumpVideoDetail() {
      this.$router.push("/shop/videoDetail?id=" + this.currVideoId)
    }
    ,
    async ajaxGetListData(dayInfo) {
      // 获取指定分类商户列表
      let {data} = await this.$ajax.gateway("/api/api_get_video_list", {
        "METHOD": "get",
        "video_type": 1
      });
      for (let i = 0; i < data.length; i++) {
        if (data[i]['start_day'] === dayInfo && data[i]['show_img'] !== "") {
          this.showImg = data[i]['show_img'];
          this.currVideoId = data[i]['id']
          this.showWrapperDialog(1);
          localStorage.setItem('index_mdmc_box', dayInfo)
          break
        }
      }
    }
    ,

    async getJsInfo() {
      // 生成网页js签名
      let {data, code} = await this.$ajax.gateway("/api/api_get_js_sign", {
        "body": {
          "url": location.href.split('#')[0]
        }
      });
      if (code === 200) {
        wx.config(data.res);
      }
      wx.ready(function () {
        wx.updateAppMessageShareData({
          title: "寻味成华美食指南", // 分享标题
          desc: "2024品味成都·食在成华·地道美食精选", // 分享描述
          link: 'https://chqmsdt.eaworks.cn/page',
          imgUrl: 'http://pic.eaworks.cn/chenghua/1.png',
          success: function (res) {
            // 设置成功
          }
        });
        wx.updateTimelineShareData({
          title:"寻味成华美食指南", // 分享标题
          desc: "2024品味成都·食在成华·地道美食精选", // 分享描述
          link: 'https://chqmsdt.eaworks.cn/page',
          imgUrl: 'http://pic.eaworks.cn/chenghua/1.png',
          success: function (res) {
            // 用户点击了分享后执行的回调函数
          }
        });
      });
      wx.error(function (res) {
        console.info("wx error", res);
      });
    }
    ,
    async ajaxGetBannerList() {
      // 获取广告数据
      let {data} = await this.$ajax.gateway("/api/api_get_banner_list", {
        "METHOD": "get"
      });
      this.homeList = data
      await this.getJsInfo();
    }
    ,
    onClickBanner({link_url}) {
      if (link_url !== "") {
        this.$router.push(link_url);
      }
    }
    ,

    onClickNav({url}) {
      if (url === "jifu") {
        this.initJF();
      } else {
        if (url.substr(0, 4) === "http") {
          window.location.href = url;
        } else {
          this.$router.push(url);
        }
      }
    }
    ,
    onClickMenu({url}) {
      this.$router.push(url);
    }
    ,
    initJF() {
      let currTime = (new Date()).valueOf();
      if (currTime > 1706803200000 && currTime < 2708790400000) {
        this.showJFWrapper = true;
        this.showFirework = true;
        this.$nextTick(() => {
          this.initFire();

          this.jfPic = "http://pic.eaworks.cn/chenghuaqu/mrjf/0224_1.jpg";
          this.showJFCard = true;
          setTimeout(() => {
            let ctx = document.getElementById("js-firework-canvas")
            ctx.remove();
            document.getElementById("js-firework-wrapper").innerHTML = '<canvas id="js-firework-canvas"></canvas>';
            this.$nextTick(() => {
              this.loadFireworkCanvasIndex++;
              this.showFirework = false;
              this.initFireworkCanvas = false;
            })
          }, 4000);

        })
      }
    }
    ,

    cancelJFWrapper() {
      if (!this.initFireworkCanvas) {
        // 关闭吉福弹窗
        this.$nextTick(() => {
          this.jfPic = "";
          this.showJFCard = false;
          this.showFirework = false;
          this.showJFWrapper = false;
          if (this.loadFireworkCanvasIndex >= 3) {
            window.location.reload();
          }
        })
      }
    }
    ,

    initFire() {
      this.initFireworkCanvas = true;
      //window.requestAnimationFrame()这个API是浏览器提供的js全局方法，针对动画效果。
      window.requestAnimationFrame = (function () {
        return window.requestAnimationFrame ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame ||
          function (callback) {
            window.setTimeout(callback, 1000)
            //每间隔10秒执行一次动画
          }
      })();
      //获取canvas区域.并设置宽和高
      var area = document.getElementById("js-firework-canvas");
      area.width = document.documentElement.clientWidth;
      area.height = document.documentElement.clientHeight;
      //转换成2d模型
      var ctx = area.getContext("2d");
      //烟花数组
      var hue = 20;//设置颜色范围
      var timerTick = 0;//计时器
      var timerTotal = 3;//每间隔5秒烟花绽放一次
      var fireworks = [];//存放烟花数组
      var particles = [];//存放碎屑数组
      //随机min和max之间的值
      function random(min, max) {
        return Math.random() * (max - min) + min;
      }

      //计算两点之间的距离
      function distans(sx, sy, tx, ty) {
        var xdistan = sx - tx;
        var ydistan = sy - ty;
        return Math.sqrt((Math.pow(xdistan, 2) + Math.pow(ydistan, 2)));
      }

      //定义烟花对象
      function Firework(sx, sy, tx, ty) {
        this.x = sx;
        this.y = sy;
        this.sx = sx;
        this.sy = sy;
        this.tx = tx;
        this.ty = ty;
        //计算两点之间的距离
        this.targetDistances = distans(sx, sy, tx, ty);
        //运行距离
        this.distancesc = 0;
        //定义变量生成的运动轨迹
        this.guiji = [];
        this.guijicount = 3;
        while (this.guijicount--) {
          this.guiji.push([this.x, this.y]);
        }
        //计算角度
        this.angle = Math.atan2(ty - sy, tx - sx);
        this.speed = 10;
        this.jiasudu = 1.05;
        this.brightness = random(50, 70);//烟花的明度
        this.targetRad = 5;//烟花小圈的半径
      }

      //更新烟花的位置
      Firework.prototype.update = function (index) {
        this.guiji.pop();
        this.guiji.push([this.x, this.y]);
        //目标圆运动
        if (this.targetRad < 8) {
          this.targetRad += 0.3;
        } else {
          this.targetRad = 1;
        }
        //根据加速度计算速度并且计算出烟花运行过程中x轴和y轴的速度
        this.speed *= this.jiasudu;
        var vx = Math.cos(this.angle) * this.speed;
        var vy = Math.sin(this.angle) * this.speed;
        //重新计算两点之间的距离
        this.distancesc = distans(this.sx, this.sy, this.x + vx, this.y + vy);
        //如果烟花运行距离大于或等于初始位置到目标位置之间的距离，生成新烟花并移除当前烟花，否则更新烟花位置
        if (this.distancesc >= this.targetDistances) {
          //生成烟花碎屑
          createparticals(this.tx, this.ty);
          //销毁烟花小圈
          fireworks.splice(index, 1)
        } else {
          this.x += vx;
          this.y += vy;
        }
      }

      //开始画运行轨迹
      Firework.prototype.draw = function () {
        ctx.beginPath();
        //轨迹的起点
        ctx.moveTo(this.guiji[this.guiji.length - 1][0], this.guiji[this.guiji.length - 1][1]);
        //绘制线条到目标点
        ctx.lineTo(this.x, this.y);
        //画出不同颜色的烟花
        ctx.strokeStyle = 'hsl(' + hue + ',100%,' + this.brightness + '%)';
        ctx.stroke();//绘制烟花轨迹
        //画出目标小圆
        ctx.beginPath();
        ctx.arc(this.tx, this.ty, this.targetRad, 0, Math.PI * 2);
        ctx.stroke();
      }

      //定义烟花碎屑方法
      function Particle(x, y) {
        this.x = x;
        this.y = y;
        this.guiji = [];
        this.guijicount = 10;
        while (this.guijicount--) {
          this.guiji.push([this.x, this.y]);
        }
        //生成任意方向的碎屑
        this.angle = random(0, 2 * Math.PI);
        this.speed = random(5, 10);//随机的速度
        this.mocal = 0.95;//摩擦力
        this.gravity = 0.98;//重力
        this.hue = random(hue - 20, hue + 20);//碎屑颜色变化范围
        this.brightness = random(50, 80);
        this.alpha = 1;//定义碎屑初始不透明
        this.decay = random(0.015, 0.03);//碎屑消失的时间
      }

      //更新碎屑
      Particle.prototype.update = function (index) {
        this.guiji.pop();
        //unshift() 方法可向数组的开头添加一个或更多元素，并返回新的长度。
        this.guiji.unshift([this.x, this.y]);
        //下面是烟花碎屑的运动
        this.speed *= this.mocal;
        this.x += Math.cos(this.angle) * this.speed;
        this.y += Math.sin(this.angle) * this.speed + this.gravity;
        this.alpha -= this.decay;//不透明度一直随时间变为0；即烟花碎屑消失
        if (this.alpha <= this.decay) {
          particles.splice(index, 1)//销毁烟花碎屑
        }
      }
      //画烟花碎屑轨迹
      Particle.prototype.draw = function () {
        ctx.beginPath();
        ctx.moveTo(this.guiji[this.guiji.length - 1][0], this.guiji[this.guiji.length - 1][1]);
        ctx.lineTo(this.x, this.y);
        //画出不同颜色的烟花利用HSL
        ctx.strokeStyle = 'hsl(' + hue + ',100%,' + this.brightness + '%)';
        ctx.stroke();
      }

      //创建碎屑
      function createparticals(x, y) {
        //设定碎屑数目
        var particalcount = 500;
        while (particalcount--) {
          //随着碎屑数目的减少为0，又重新调用碎屑方法
          particles.push(new Particle(x, y))
        }
      }

      //获取屏幕的宽和高
      var clientw = document.documentElement.clientWidth;
      var clienth = document.documentElement.clientHeight;

      function loop() {
        //requestAnimationFrame() 方法来告诉浏览器需要执行的动画，
        // 并让浏览器在下一次重绘之前调用指定的函数来更新动画。
        requestAnimationFrame(loop);
        hue += 0.1;
        //在源图像外显示目标图像。只有源图像外的目标图像部分会被显示，源图像是透明的。
        ctx.globalCompositeOperation = 'destination-out';
        ctx.fillRect(0, 0, clientw, clienth);
        ctx.fillStyle = 'rgb(0,0,0,0.5)';
        //显示源图像和目标图像。
        ctx.globalCompositeOperation = 'lighter';
        var i = fireworks.length;
        while (i--) {
          fireworks[i].draw();
          fireworks[i].update(i);
        }
        var i = particles.length;
        while (i--) {
          particles[i].draw();
          particles[i].update(i);
        }
        //此时，我们还没有创建任何的烟花。我们希望设置一个定时时间timerTotal，周期性的
        // 产生一个烟花，我们也需要一个时间计数timerTick，在每次帧更新的时候加1，记下帧更新的次数。
        if (timerTick >= timerTotal) {
          fireworks.push(new Firework(clientw / 2, clienth, random(0, clientw), random(0, clienth)));
          timerTick = 0;
        } else {
          timerTick++;
        }
      }

      window.οnlοad = loop();
    }
    ,

    onClickJFPic() {
      console.log("-> onClickJFPic");
    }

  }
}
;
</script>

<style lang="less" scoped>
.index_bottom_image {
  position: absolute;
  bottom: 0;
  width: 100%;
}


.h5-home {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("https://pic.eaworks.cn/chenghua/index_main_head.png") no-repeat top center;
  background-size: 100% auto;

  .home-banner {
    padding: 0.5rem 0.5rem 0.2rem;
    position: relative;

    img._lndj {
      width: 44%;
      position: absolute;
      left: 28%;
      top: 0.25rem;
      z-index: 9;
    }

    .my-swipe {
      img {
        display: block;
        width: 100%;
      }
    }
  }

  .home-nav {
    width: 100%;
    height: 11.5rem;
    position: absolute;
    top: 4rem;
    opacity: 0.8;
    left: 0;
    background: url("https://pic.eaworks.cn/chenghua/nav_bg.png") no-repeat top center;
    background-size: 98% 100%;
    z-index: 1;

    .nav-wrapper {
      padding: 2.2vh 0.6rem 0;

      img {
        width: 3.8rem;
        display: block;
        margin: 0.5rem auto 0;
      }
    }

    .menu-wrapper {
      padding: 3.4vh 0.8rem 0;

      img {
        width: 2.4rem;
        display: block;
        margin: 0.4rem auto 0;
      }
    }
  }
}

.firework-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  color: #FDF8B8;
  background: rgba(0, 0, 0, 0.8);
  line-height: 15rem;
  text-align: center;

  .jf-canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .jf-pic {
    position: relative;
    z-index: 1;
    -webkit-animation: fade-in-fwd 3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-fwd 3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

    img._top {
      display: block;
      width: 42vh;
      margin: 3vh auto 0;
    }

    img._bottom {
      display: block;
      width: 42vh;
      margin: 0 auto;
    }

    ._pic {
      padding: 2vh 0;

      img {
        display: block;
        width: 32vh;
        margin: 0 auto;
      }
    }
  }
}

@-webkit-keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.dialog-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.45);

  .dialog-content {
    .img-wrapper {
      img {
        display: block;
        width: 100%;
        max-height: 10rem;
      }
    }
  }

  .dialog-closed {
    img {
      display: block;
      width: 1rem;
      margin: 0 auto;
    }
  }
}
</style>
